$( document ).ready(function() {

    // inicialise iCheck
    $('input').iCheck({
        checkboxClass: 'icheckbox',
        radioClass: 'iradio'
    });

    // inicialise tooltips
    $('[data-toggle="tooltip"]').tooltip()
    // icialise popoveru
    $('[data-toggle="popover"]').popover()

    // add parent element DIV.select-wrapper
    if ($("select.form-control").length > 0) {
        $('select.form-control').each(function (idx,elm) {
            selector = '';
            if($(elm).hasClass('input-lg')) { selector=' input-lg'; }
            if($(elm).hasClass('input-sm')) { selector=' input-sm'; }
            if(!$(elm).hasClass('combobox')) {
                $(elm).wrap("<div class='select-wrapper"+selector+"'></div>");
            }
        });
    }

    // add/remove class .focus to parent of .form-control on events focus/focusout
    $(".form-control").focus(function() {
        $(this).closest('.form-group').addClass('focus');
    });
    $(".form-control").focusout(function() {
        $(this).closest('.form-group').removeClass('focus');
    });

    // prevent clics to disabled tabs
    $(".nav-tabs a[data-toggle=tab]").on("click", function(e) {
        if ($(this).parent().hasClass("disabled")) {
            e.preventDefault();
            return false;
        }
    });

    // add .active to accordion after open
    $('.panel-collapse.collapse.in').parent().addClass('active');
    $('.panel').on('show.bs.collapse', function () {
        $(this).addClass('active');
    });
    $('.panel').on('hide.bs.collapse', function () {
        $(this).removeClass('active');
    });

    // menu dropdown inicialise
    $('.dropdown').on('shown.bs.dropdown', function () {
        if ($(this).find('.tabs-wrapper .col .tab-pane').length > 1) {
            $(this).find('.tabs-wrapper .col .tab-pane').css('display', 'block');
            var maxHeight = 0;
            maxHeight = Math.max.apply(null, $(this).find('.tabs-wrapper .col .tab-pane').map(function ()
            {
                return $(this).height();
            }).get());

            if ($(window).width() >= 768) $(this).find('.tabs-wrapper .col .tab-pane').height(maxHeight);
            $(this).find('.tabs-wrapper .col .tab-pane').css('display', '');
        } else {
            $(this).find('.tabs-wrapper .col .tab-pane').css('display', 'block');
            $(this).find('.tabs-wrapper .col .tab-pane').parent().height($(this).find('.tabs-wrapper .col .tab-pane').eq(0).height());
            $(this).find('.tabs-wrapper .col .tab-pane').css('display', '');
        }
        if ($(window).width() >= 768) $(this).find('.tabs-wrapper .col').equalHeights();
    })
    $(document).on('click.bs.dropdown.data-api', '.dropdown-menu', function (e) { e.stopPropagation() })

    // menu mobile collapse
    $('.navbar-menu-mobile').on('shown.bs.collapse', function () {
        $('.navbar-menu-mobile').not(this).collapse('hide');
    });

    if ($(".scrollable-horizontal").length) {
        // Horizontal scrollbar
        var ps = new PerfectScrollbar('.scrollable-horizontal', {
            suppressScrollY: true,
            minScrollbarLength: 50
        });
    }

    // add .active to accordion after open
    $('.panel-collapse.collapse.in').parent().addClass('active');
    $('.panel').on('show.bs.collapse', function () {
        $(this).addClass('active');
    });
    $('.panel').on('hide.bs.collapse', function () {
        $(this).removeClass('active');
    });

    /* nechceme ve finalnim JS, pokud je potreba ladit, musi se odkomentovat
    var users = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        prefetch: {
            url: 'js/users.json',
            filter: function(list) {
                return $.map(list, function(user) {
                    return { name: user }; });
            }
        }
    });
    users.initialize();

    $('input.tagsinput').tagsinput({
        typeaheadjs: {
            name: 'users',
            displayKey: 'name',
            valueKey: 'name',
            source: users.ttAdapter()
        }
    });
    */

    $('.datepicker').datepicker();

    var $select2_objects = $( ".select2-control" );
    $select2_objects.select2({
        theme: "bootstrap",
        dropdownAutoWidth : false,
        width: 'element'
    });
    // add/remove class .focus to parent of .form-control on events focus/focusout
    $select2_objects.on("select2:open", function (e) {
        $(this).closest('.form-group').addClass('focus');
        $('.select2-container--open').css('width', $('.select2-container--open').find('.select2-dropdown').css('width'));
    });
    $select2_objects.on("select2:close", function (e) {
        $(this).closest('.form-group').removeClass('focus');
    });


});